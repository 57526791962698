/* eslint-disable react-perf/jsx-no-jsx-as-prop */
import React, { Suspense } from 'react';

const ComparePopup = React.lazy(() => import(
    /* webpackChunkName: "compare-popup" */
    './ComparePopup'
));

const FallBackComponent = () => null;

const ComparePopupAsync = props => {
    if (typeof window === 'undefined') {
        return null;
    }

    return (
        <Suspense fallback={ <FallBackComponent /> } >
            <ComparePopup { ...props } />
        </Suspense>
    );
};

export default ComparePopupAsync;
