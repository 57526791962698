/**
 * Keep on adding Gen config's key map here
 */
export const GEN_CONFIG = {
    siteCode: 'siteCode'
};

export const SITE_CODE = {
    OLX_TR: 'olxtr',
    OLX_MX: 'olxmx'
};
