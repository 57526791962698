/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import withRouter from 'HOCs/withRouter';
import IconWrapper from 'Components/IconWrapper/IconWrapper';
import ItemFavouriteIcon from 'Components/ItemFavouriteIcon/ItemFavouriteIcon';
import ItemImage from 'Components/Listing/components/ItemImage';
import CallButton from 'Components/Listing/components/CallButton/CallButton';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import VasTagsBottomInfo from '../VasTagsBottomInfo/VasTagsBottomInfo';
import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import withBackButtonHandling from 'HOCs/withBackButtonHandling/withBackButtonHandling';
import useItemInfo from 'Hooks/useItemInfo';
import { getVasConfigValues, getLocale, configSelector } from 'Selectors/config';

import { isCocoFofoUser, getIsSponsoredAd } from 'Helpers/item';
import { ITEM_SOURCE } from 'Constants/items';
import { LISTING_TYPES, trustTagHash, DEFAULT_SIZE_BY_VISUALIZATION } from 'Constants/listing';
import { BLACKBACKGROUND2, MONETIZATIONDARK1 } from 'Constants/colors';
import { IS_DESKTOP, IS_MOBILE } from 'Constants/device.APP_TARGET';

import css from './ItemCardCarInd.APP_TARGET.scss';
import isEmpty from 'lodash/isEmpty';
import { isMobile } from 'Helpers/devices';
import SpinViewTag from 'Components/Listing/components/SpinViewTag';
import { setSelectFrom } from 'Actions/track';
import { getSelectFromFilter } from 'Helpers/tracking';
import { getItemWithLocation } from 'Helpers/item';
import { getStatus } from '../ItemCardStatusMX.utils';
import { convertKeysToSnakeCase } from 'Helpers/objects';
import { getDealerDataSelector, getDealerPreviewDataSelector } from 'Selectors/user';
import { getUserInfoByCategory } from 'Helpers/users';

export const ItemCardCarInd = ({
    userType,
    chosenOption,
    config,
    enabledRealImpressions,
    friendsInCommon,
    isAdSuggested,
    item,
    listingType,
    location,
    onItemClick,
    onView,
    source,
    tag,
    trackerOrigins,
    visualizationType,
    vasConfigValues,
    onVasTagTap,
    track,
    showFullPrice,
    showOlxAutosAdCardTag,
    selectFrom,
    hideVasStrip,
    imageHeight,
    imageWidth,
    generalConfig,
    listingBodyType,
    setSelectFromTrack,
    categories,
    showSponseredTag,
    showDealerTag,
    dealerData,
    isDealerProfile,
    isDealerPreview,
    userIcon
}) => {
    const {
        category_id,
        id,
        image,
        isFeatured,
        isInspected,
        price,
        scoreFactor,
        slug,
        spell_id,
        title,
        user_id,
        user_type,
        subtitle,
        make,
        model,
        subDetails,
        isSpinViewAvailable
    } = useItemInfo(getItemWithLocation(item, config), categories);
    const adpvConfig = config.get('adpvAuto');
    const showFeaturedTag = adpvConfig?.featuredTag;
    const showFavBtn = adpvConfig?.showFavBtn;
    const hideVasTagListing = config.get('hideVasTagListing');
    const phoneInListing = generalConfig?.features?.find(feature => feature.name === 'olxautos_phone_in_listing') || {};

    const staticAssetUrl = config.get('staticAssets');
    const isSponsored = getIsSponsoredAd(item) && showSponseredTag;

    const status = getStatus({ showFeaturedTag, isFeatured, tags: item?.tags || convertKeysToSnakeCase(item?.monetizationInfo?.tags), css: css.status, isSponsored, staticAssetUrl });

    const getLinkToProp = () => {
        const state = { isAdSuggested, source, select_from: selectFrom };

        // Add fields for tracking related ads
        if (source === ITEM_SOURCE.RELATED) {
            state.relatedAdTracking = {
                impressions: [{
                    adId: id,
                    scoreFactor: typeof scoreFactor === 'undefined' ? 'null' : scoreFactor
                }],
                chosen_option: typeof chosenOption === 'undefined' ? 'null' : chosenOption,
                origin: trackerOrigins.ITEM_PAGE
            };
        }

        // Add tracking for featured ad
        state.featuredAdTracking = { chosen_option: typeof chosenOption === 'undefined' ? 'null' : chosenOption };

        if (location && location.state && location.state.fromBundleResultset) {
            state.fromBundleResultset = location.state.fromBundleResultset;
        }

        return {
            pathname: slug,
            state
        };
    };

    const onViewChange = inView => {
        if (!enabledRealImpressions) {
            return;
        }
        if (inView) {
            onView({
                id,
                title,
                user_id,
                isFeatured,
                spell_id,
                isInspected
            });
        }
    };

    const onClick = () => {
        const selectFromFilter = getSelectFromFilter(listingBodyType);

        setSelectFromTrack(selectFromFilter);
        onItemClick(item);
    };

    const userTypeLogo = userIcon
        ? (<img
            alt="user_icon"
            src={ userIcon }
            className={ css.userTypeLogo }
        />)
        : null;

    let dealerTag;

    if (isDealerProfile || isDealerPreview) {
        dealerTag = dealerData?.showroom_status && dealerData?.showroom?.logo?.url && <img
            alt="dealer_logo"
            src={ dealerData.showroom.logo.url }
            className={ css.dealerTag }
        />;
    }
    else {
        dealerTag = showDealerTag && item?.dealer_showroom_enabled && item?.dealer_logo_url && <img
            alt="dealer_logo"
            src={ item?.dealer_logo_url }
            className={ cx(css.olxAutosTag, css.dealerTag) }
        />;
    }

    const enableCall = isCocoFofoUser((user_type), item?.category_id) && phoneInListing.enabled;

    return (
        <ReactObserver
            tag={ tag }
            data-aut-id="itemBox2"
            data-aut-category-id={ category_id }
            onChange={ onViewChange }
            className={ css[`${visualizationType}ItemCardCarInd`] }>
            <Link
                to={ !isDealerPreview && getLinkToProp() }
                className={ cx({
                    [css.withStatusTagRow]: IS_MOBILE && !userTypeLogo && !status
                }) }
                onClick={ !isDealerPreview && onClick } >
                <div className={ css.topContainer }>
                    {isSpinViewAvailable && <SpinViewTag />}
                    {image
                        && <ItemImage
                            image={ image }
                            friendsInCommon={ friendsInCommon }
                            defaultSize={ DEFAULT_SIZE_BY_VISUALIZATION[visualizationType] }
                            alt={ title }
                            visualizationType={ visualizationType }
                            listingType={ listingType }
                            height={ imageHeight }
                            width={ imageWidth }
                        />
                    }

                    { dealerTag ? <span className={ css.dealerContainer }>
                        {dealerTag}
                    </span> : null}

                    {IS_DESKTOP && (item.video_status
                    && <div className={ css.videoOverDesktop }>
                        <IconWrapper className={ css.videoIconDesktop } icon="play" size={ 12 } color="white" />
                        <span className={ css.videoIconDesktop }>Video</span>
                    </div>)}

                    {IS_MOBILE && (item.video_status
                    && <div className={ `${css.videoOverMobile} ${ (isFeatured || isCocoFofoUser((user_type), item?.category_id)) ? css.videoOverFeatured : '' }` }>
                        <IconWrapper className={ css.videoIconMobile } icon="play" size={ 12 } color="white" />
                        <span className={ css.videoIconMobile }>Video</span>
                    </div>)}

                    {(IS_DESKTOP || (IS_MOBILE && visualizationType === 'grid'))
                        && showOlxAutosAdCardTag && <div className={ css.statusTags }>
                        {userTypeLogo}
                        {status}
                    </div>
                    }
                    {
                        listingType === LISTING_TYPES.ITEMS && (
                            <div className={ css.resume }>
                                {IS_MOBILE && visualizationType === 'list'
                                    && showOlxAutosAdCardTag && <div className={ css.statusTags }>
                                    {userTypeLogo}
                                    {status}
                                </div>
                                }
                                {price && (
                                    <span data-aut-id="itemPrice" className={ cx(css.price, { [css.fullWidth]: showFullPrice }) } >
                                        {price}
                                    </span>
                                )}
                                {subtitle && !enableCall
                                    && <div
                                        className={ css.subTitle }
                                        title={ subtitle }
                                        data-aut-id="itemSubTitle"
                                    >
                                        {subtitle}
                                    </div>
                                }
                                {make && model
                                    && <div
                                        className={ css.title }
                                        title={ title }
                                        data-aut-id="itemTitle"
                                    >
                                        {`${make} ${model}`}
                                    </div>}
                                {!isEmpty(subDetails) && !enableCall
                                    && <div className={ css.details }
                                        data-aut-id="itemDetails"
                                    >
                                        {subDetails.itemLocation}
                                        {subDetails.displayDate}
                                    </div>
                                }
                                { enableCall && <CallButton phoneNumber={ phoneInListing.data?.number } item={ item } data-aut-id="itemCardCallBtn" />}
                            </div>
                        )
                    }
                </div>
                {listingType === LISTING_TYPES.ITEMS && (
                    <div className={ cx({ [css.vasTagsWrapperHide]: hideVasTagListing || hideVasStrip, [css.vasTagsWrapperShow]: !hideVasTagListing && !hideVasStrip }) } data-aut-id="vas-tags-bottom-info-cont">
                        {!hideVasTagListing && <VasTagsBottomInfo
                            className={ css.bottomCardInfo }
                            itemId={ id }
                            vasConfigValues={ vasConfigValues }
                            gradient={ isCocoFofoUser((user_type || userType), item?.category_id) ? MONETIZATIONDARK1 : BLACKBACKGROUND2 }
                            onVasTagTap={ onVasTagTap }
                            onTrack={ track }
                        />}
                    </div>
                )}
            </Link>
            {
                showFavBtn
                    && listingType === LISTING_TYPES.ITEMS
                && <ItemFavouriteIcon
                    item={ item }
                    className={ css.favIcon }
                    btnClassName={ css.favBtn }
                    size={ isMobile ? '14' : '24' }
                    color="white"
                />
            }
        </ReactObserver>
    );
};

ItemCardCarInd.propTypes = {
    userType: PropTypes.string,
    config: PropTypes.object.isRequired,
    item: PropTypes.object,
    onView: PropTypes.func,
    friendsInCommon: PropTypes.bool,
    trackerOrigins: PropTypes.object,
    enabledRealImpressions: PropTypes.bool,
    visualizationType: PropTypes.oneOf([
        'grid', 'big', 'list', 'smallGrid'
    ]),
    listingType: PropTypes.string,
    onItemClick: PropTypes.func,
    tag: PropTypes.string,
    isAdSuggested: PropTypes.bool,
    source: PropTypes.string,
    chosenOption: PropTypes.number,
    location: PropTypes.object,
    vasConfigValues: PropTypes.array,
    onVasTagTap: PropTypes.func,
    track: PropTypes.func.isRequired,
    showFullPrice: PropTypes.bool,
    showOlxAutosAdCardTag: PropTypes.bool,
    selectFrom: PropTypes.string,
    hideVasStrip: PropTypes.bool,
    imageHeight: PropTypes.number,
    imageWidth: PropTypes.number,
    generalConfig: PropTypes.object.isRequired,
    listingBodyType: PropTypes.string,
    setSelectFromTrack: PropTypes.func,
    categories: PropTypes.object,
    showSponseredTag: PropTypes.bool,
    showDealerTag: PropTypes.bool,
    dealerData: PropTypes.object,
    isDealerPreview: PropTypes.bool,
    isDealerProfile: PropTypes.bool,
    userIcon: PropTypes.string
};

ItemCardCarInd.defaultProps = {
    userType: '',
    item: {},
    onView: () => { },
    friendsInCommon: false,
    visualizationType: 'grid',
    enabledRealImpressions: false,
    listingType: LISTING_TYPES.ITEMS,
    onItemClick: () => { },
    tag: 'li',
    isAdSuggested: false,
    chosenOption: null,
    trackerOrigins: {},
    vasConfigValues: [],
    financeConfig: {},
    currentLocale: '',
    onVasTagTap: () => {},
    showFullPrice: false,
    showOlxAutosAdCardTag: true,
    hideVasStrip: false,
    listingBodyType: '',
    setSelectFromTrack: () => {},
    categories: {},
    showSponseredTag: false,
    showDealerTag: false,
    isDealerPreview: false,
    isDealerProfile: false
};

export const mapStateToProps = (state, ownProps) => {
    const user = state.users.elements[ownProps.item.user_id];
    const userVasTags = user && user.additional_services || [];
    const { isDealerPreview, categoryId } = ownProps;
    const dealer = getUserInfoByCategory(user, categoryId);

    return {
        userType: (dealer.dealer_type || '').toLowerCase(),
        userIcon: ownProps.item?.user_icon || dealer?.icon_url,
        currentLocale: getLocale(state),
        selectFrom: (ownProps.location.state && ownProps.location.state.source),
        vasConfigValues: getVasConfigValues(state, ownProps.item.vasTags || userVasTags || []),
        financeConfig: state.financeConfig || {},
        generalConfig: configSelector(state),
        categories: state.categories,
        dealerData: isDealerPreview ? getDealerPreviewDataSelector(state) : getDealerDataSelector(state)
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        setSelectFromTrack: origin => dispatch(setSelectFrom(origin))
    };
};

export default compose(
    withConfig,
    withTrack,
    withRouter,
    withBackButtonHandling(trustTagHash),
    connect(mapStateToProps, mapDispatchToProps)
)(ItemCardCarInd);
