import React from 'react';
import PropTypes from 'prop-types';
import { SVG_TYPE } from 'Constants/images';
import classNames from 'classnames';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import css from './BrandPromiseItem.scss';

const BrandPromiseItem = ({ id, label, imageURI, isListingPage, isAdPage }) => {
    return (
        <div
            data-aut-id="brandBannerListItem"
            key={ id }
            className={ classNames(css.container, {
                [css.listPageContainer]: isListingPage,
                [css.adPageContainer]: isAdPage
            }) }
        >
            <div className={ css.imgWrapper } data-aut-id="brandBannerListImage">
                <ImageWrapper fileName={ imageURI } className={ css.bpImage } types={ SVG_TYPE } />
            </div>
            <div className={ css.label } data-aut-id="brandBannerListItemTitle">{label}</div>
        </div>
    );
};

BrandPromiseItem.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    imageURI: PropTypes.string.isRequired,
    isListingPage: PropTypes.bool,
    isAdPage: PropTypes.bool
};

BrandPromiseItem.defaultProps = {
    isListingPage: false,
    isAdPage: false
};

export default BrandPromiseItem;
